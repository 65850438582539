/* General styles */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h3 {
  margin-top: 0;
  color: #333;
}

/* Layout */
.app {
  display: flex;
  height: 100vh;
  background-color: #f7f7f7;
}

/* Sidebar (Explorer) */
.sidebar {
  width: 25%;
  background-color: #ffffff;
  border-right: 1px solid #ddd;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.explorer h3 {
  font-size: 18px;
  color: #444;
}

.explorer div {
  margin-bottom: 10px;
}

/* Clickable items for Tables/Views */
.table-item, .view-item {
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 5px;
}

.table-item:hover, .view-item:hover {
  background-color: #f0f0f0;
}

/* Main Content (Details) */
.content {
  width: 75%;
  padding: 20px;
  overflow-y: auto;
}

.details {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Scrollable table container */
.details .scrollable-table {
  overflow-x: auto; /* Allows horizontal scrolling */
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

/* Table styles */
.records-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 600px; /* Minimum width for the table */
}

.records-table th, .records-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.records-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  position: sticky;
  top: 0;
  z-index: 2; /* Ensures the header stays on top when scrolling */
}

.records-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.records-table tr:hover {
  background-color: #f1f1f1;
}

/* Styles for view definition */
.details pre {
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;  /* Horizontal scrolling for long content */
  white-space: pre-wrap;  /* Wrap text to fit within the element */
  word-wrap: break-word;  /* Break long words if needed */
  border: 1px solid #ddd;
  max-height: 400px;  /* Optional: Limit height to make it scrollable if very long */
  overflow-y: auto;  /* Vertical scrolling if the definition is too long */
  margin-top: 10px;
}

/* Responsive design */
@media (max-width: 768px) {
  .app {
    flex-direction: column;
  }

  .sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid #ddd;
    box-shadow: none;
  }

  .content {
    width: 100%;
  }
}
